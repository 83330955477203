.container + .container {
    margin-top: 4px;
}

.caption {
    font-weight: 600;
    line-height: 17px;
}

.captionMsiteRedesign {
    font-weight: 500;
    line-height: 15px;
}

.reload {
    background-color: transparent;
    border: none;
    color: #547dff;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    outline: none;
    padding: 0;
}

.reloadMsiteRedesign {
    font-weight: 500;
    line-height: 16px;
}

.reload:hover {
    color: #2754e5;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 8px 12px;
}

.headerMsiteRedesign {
    padding: 12px;
}
