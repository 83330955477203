.container {
    position: relative;
    padding: 0 12px;
    font-weight: 400;
    line-height: 41px;
    border-radius: 6px;
    cursor: pointer;
}

.containerMsiteRedesign {
    display: flex;
    align-items: center;
    gap: 6px;
}

.container:hover,
.container.active,
.container.active:hover {
    background-color: #f4f4f5;
}

.containerMsiteRedesign:hover,
.containerMsiteRedesign.active,
.containerMsiteRedesign.active:hover {
    background-color: #eef2f6;
}

.container.inactive,
.container.inactive:hover {
    background-color: #fff;
}

.remove {
    background-color: transparent;
    color: #a0a0ab;
    padding: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
}

.removeMsiteRedesign {
    width: 16px;
    height: 16px;
}

.timerIconMsiteRedesign {
    width: 16px;
    height: 16px;
    color: #a0a0ab;
}

.arrowRightIconMsiteRedesign {
    background-color: transparent;
    padding: 0;
    position: absolute;
    right: 12px;
    top: 50%;
    border: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    transform: translateY(-50%) rotate(180deg);
    color: #a0a0ab;
}

.arrowRightIconMsiteRedesign:hover {
    color: #18181b;
}

.remove:hover {
    color: #18181b;
}

.keyword {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-right: 20px;
}

.suggestHighlight {
    font-weight: bold;
}
