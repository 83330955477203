.container {
    position: absolute;
    display: block;
    top: 100%;
    padding-top: 5px;
    left: 0;
    right: 0;
    user-select: none;
}

.content {
    padding: 4px;
    border-radius: 12px;
    background-color: #fff;
}

.contentMsiteRedesign {
    padding: 0;
}

.backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.scrollLocker {
    height: 100vh;
    overflow: hidden;
    touch-action: none;
    zoom: 1;
}

@media (--snow-tablet) {
    .isSticked .content {
        border: 1px solid #e6eaf0;
    }
    .backdrop {
        background-color: rgba(34, 34, 34, 0.68);
        opacity: 0.45;
    }
}
