.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}

.form {
    position: relative;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    background-color: #cdd5df;
    border-radius: 10px;
    padding: 4px;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}

.formMsiteRedesign {
    border-radius: 100px;
    background-color: #f0f4f7;
    position: static;
}

.input {
    padding: 4px 8px 4px 40px;
    flex-grow: 1;
    width: 100%;
    font-weight: 600;
    outline: none;
    border: none;
    border-radius: 6px;
    background-color: #fff;
    height: 48px;
}

.inputMsiteRedesign {
    height: 32px;
    padding: 8px 8px 8px 12px;
    background-color: #f0f4f7;
}

.arrowLeftIconMsiteRedesignWrapper {
    display: none;
    cursor: pointer;
    min-width: 42px;
    height: 32px;
    background: #eef2f6;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
}

.isOpened .arrowLeftIconMsiteRedesignWrapper {
    display: flex;
}

.arrowLeftIconMsiteRedesign {
    width: 16px;
    height: 16px;
    color: #6c727e;
    -webkit-tap-highlight-color: transparent;
}

.clearIconMsiteRedesign {
    width: 16px;
    height: 16px;
}

.backIcon,
.reset,
.submit {
    user-select: none;
}

.input::placeholder,
.input::-webkit-input-placeholder {
    font-weight: 500;
    color: #a0a0ab;
}

.isOpened .input {
    margin-left: 40px;
}

.isOpened .inputMsiteRedesign {
    margin-left: 0;
}

.submit,
.reset {
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}

.submit {
    background-color: #bef550;
    border-radius: 8px;
    color: #18181d;
    min-width: 80px;
}

.submitMsiteRedesign {
    border-radius: 100px;
    min-width: 48px;
}

.hiddenSubmitButton {
    display: none;
}

.searchIconMsiteRedesign {
    height: 16px;
    width: 16px;
    -webkit-tap-highlight-color: transparent;
}

.submit:focus,
.submit:hover {
    background-color: #b8f05b;
}

.reset {
    display: block;
    color: #9aa4b2;
    flex-shrink: 0;
    width: 40px;
    background-color: #fff;
    margin-left: -6px;
    border-radius: 0 6px 6px 0;
    padding: 0 8px;
}

.reset svg {
    vertical-align: middle;
}

.resetMsiteRedesign {
    color: #a0a0ab;
    background-color: inherit;
    display: none;
}

.shownResetMsiteRedesign {
    display: block;
}

.input:placeholder-shown ~ .reset {
    display: none;
}

.searchIcon {
    color: #9aa4b2;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.isOpened .input ~ .searchIcon {
    left: 48px;
}

.backIcon {
    display: none;
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    color: #6c727e;
    cursor: pointer;
}

.isOpened .backIcon {
    display: block;
}

@media (--snow-tablet) {
    .input {
        height: 100%;
        padding-left: 12px;
    }
    .form {
        padding: 0;
        border-radius: 6px;
        background-color: #fff;
        height: 100%;
    }
    .formMiniProfileRedesign {
        border-radius: 8px;
    }
    .searchIcon {
        display: none;
    }
    .submit {
        margin: 2px 3px 2px 0;
        border: 1px solid #b2ed50;
        background-color: #ccff66;
        border-radius: 100px;
    }
    .submitMiniProfileRedesign {
        margin: 4px 4px 4px 0;
        background-color: #bef550;
    }
    .isOpened .backIcon {
        display: none;
    }
    .isOpened .input {
        margin-left: 0;
    }
    .isSticked .input,
    .isSticked.form {
        background-color: #eef2f6;
    }

    .isSticked .input {
        padding-left: 12px;
    }
    .reset {
        background-color: transparent;
    }
}

@media (--snow-desktop) {
    .input {
        padding-left: 16px;
    }
    .isSticked .input {
        padding-left: 16px;
    }
    .submit {
        min-width: 104px;
        margin: 4px 5px 4px 0;
    }
    .isSticked .submit {
        min-width: 76px;
        margin: 2px 3px 2px 0;
    }
    .submitMiniProfileRedesign {
        margin: 6px 6px 6px 0;
    }
    .isStickedMiniProfileRedesign .submitMiniProfileRedesign {
        min-width: 104px;
        margin: 6px 6px 6px 0;
    }
}
